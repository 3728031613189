const {name, version} = require('../../package.json');
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const nordseeCustomerGroup = 'nordsee';
export const customerGroup = nordseeCustomerGroup;
const customerAnalyticsIds = {
	subway: 'UA-78275012-3'
};
const facebookPixelId = {
	kochloeffel: null
};
const supportEmail = {
	nordsee: 'nordsee@smoothr.de',
};
const firebaseConfig = {
	nordsee: {
		apiKey: "AIzaSyDzopA0HXMwlMKLKBu93uzX5y_1EgseEec",
		authDomain: "dev-env-216009.firebaseapp.com",
		databaseURL: "https://dev-env-216009.firebaseio.com",
		projectId: "dev-env-216009",
		storageBucket: "dev-env-216009.appspot.com",
		messagingSenderId: "1004449889395",
		appId: "1:1004449889395:web:5862364992bec5c0e0fe06",
		measurementId: "G-HC79NF4C4S"
	  },
};
export const environment = {
	production: false,
	packageName: name,
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	flavor: 'devDebug',
	placesApiKey: 'AIzaSyBmncPgMIKWShlEl_mxWcwjfUX92oA3slg',
	baseUrl: 'https://dev-api.smoothr.de',
	customerAnalyticsId: customerAnalyticsIds[customerGroup],
	version,
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	facebookPixelId: facebookPixelId[customerGroup],
	VR_URL: 'https://test.vr-pay-ecommerce.de/v1/paymentWidgets.js',
	PAYPAL: {
		BN_CODE: 'SterlixGmbH_MP_Connected',
		JS_URL:
			'https://www.paypal.com/sdk/js?client-id=Ac5vAqN4prOBX3YUFPX_L9Bmz3ncsdmOgkz7LuE2zP3yDbIaJB0y3SD4CTBdJqoHQhcWP6-WMklA-thJ&integration-date=2020-09-04&components=buttons,funding-eligibility&currency='
	},
	APAY_MERCHANT_ID: 'merchant.smoothr.selforder-dev',
	GPAY: {
		GATEWAY: 'aciworldwide',
		MERCHANT_ID: 'BCR2DN6T5OYNT6CG'
	},
	countryList: ['de', 'au']
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
