import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountPage } from './account.page';
import { SharedModuleModule } from '../../shared-module/shared-module.module';

const routes: Routes = [
	{
		path: '',
		component: AccountPage,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes), SharedModuleModule],
	exports: [RouterModule],
})
export class AccountPageRoutingModule {}
