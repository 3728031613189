import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import Localization from '../../../smoothr-web-app-core/models/Localization';

@Component({
	selector: 'app-info-modal',
	templateUrl: './info-modal.component.html',
	styleUrls: ['info-modal.component.scss'],
})
export class InfoModalComponent {
	desc: Localization;
	environment = environment;

	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, desc: Partial<Localization>) {
		const modal = await modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: InfoModalComponent,
			componentProps: {
				desc
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
