import {Component, EventEmitter, Input, Output} from '@angular/core';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
import Article from 'src/smoothr-web-app-core/models/Article';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import { getAvailability, getPrice } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-option-group-overlay',
	templateUrl: './article-option-group-overlay.component.html',
	styleUrls: ['article-option-group-overlay.component.scss']
})
export class ArticleOptionGroupOverlayComponent {
	optionGroupData: OptionGroup;
	@Input() set optionGroup(value:OptionGroup) {
		console.log(value)
		this.optionGroupData = value;
	}
	@Input() currency: string;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;
	getAvailability= getAvailability;
	// Utils = Utils;
	OrderType = OrderType;
	selectedArticle: Article;

	constructor() {
	}

	get price() {
		return getPrice(
			this.optionGroup.articles.find(
				article =>
					getPrice(article, OrderType.PREORDER, this.preorderType) > 0
			),
			OrderType.PREORDER,
			this.preorderType
		);
	}

	select(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.quantity = 1;
		articleOption.group = this.optionGroup._id;
		this.selection.emit(articleOption);
	}

	selectArticle(article: Article) {
		this.selectedArticle = article;
		this.select(article);
	}
}
