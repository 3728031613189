import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';
import { MenuModalComponent } from '../menu-modal/menu-modal.component';

@Component({
	selector: 'app-burger-btn',
	templateUrl: './burger-btn.component.html',
	styleUrls: ['burger-btn.component.scss'],
})
export class BurgerBtnComponent {
	visible: boolean;
	@Input() white = true;
	constructor(private modalCtrl: ModalController) {}

	async presentPopover(ev: any) {
		const popover = await this.modalCtrl.create({
			component: MenuModalComponent,
			backdropDismiss: true,
			showBackdrop: true,
			cssClass: 'menu-modal'
		});
		popover.onWillDismiss();
		await sleep(100);
		await popover.present();
		return;
	}
}
