import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
import Article from 'src/smoothr-web-app-core/models/Article';
import { getPrice, numberToCurrency } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-kl-overlay-option-item',
	templateUrl: './kl-overlay-option-item.component.html',
	styleUrls: ['kl-overlay-option-item.component.scss']
})
export class KlOverlayOptionItemComponent implements OnInit {
	@Input() article: Article;
	@Input() preorderType: PreorderType;
	@Input() selected: boolean;
	@Input() currency: string;
	numberToCurrency=numberToCurrency;
	@Output() selection = new EventEmitter<Article>();


	constructor() {}

	get price(): number {
		return getPrice(this.article, OrderType.PREORDER, this.preorderType);
	}

	ngOnInit() {}

	selectArticle(article: Article) {
		this.selection.emit(article);
	}
}
