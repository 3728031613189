import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { AllowGpsModalComponent, AllowGpsModalResult } from '../allow-gps-modal/allow-gps-modal.component';
import { MapsUtils } from '../../../smoothr-web-app-core/utils/maps-utils';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import Address from '../../../smoothr-web-app-core/models/Address';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';

import MarkerClusterer from '@googlemaps/markerclustererplus';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import { sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';

export class SelectVenueModalResult {
	constructor(public venue: Venue, public address: Address, public preorderType: PreorderType) {
	}
}

@Component({
	selector: 'app-select-venue-modal',
	templateUrl: './select-venue-modal.component.html',
	styleUrls: ['select-venue-modal.component.scss'],
})
export class SelectVenueModalComponent implements OnInit {
	@ViewChild(IonInput, { static: true }) searchBar: IonInput;
	venues: Venue[] = [];
	loading = true;
	allowGps: boolean;
	preorderType: PreorderType;
	selectedVenue: Venue = null;
	environment = environment;
	clusterer: MarkerClusterer = null;
	error: string;
	predictions: AutocompletePrediction[] = [];
	MapsUtils = MapsUtils;
	searchTerm = '';
	predictionsVisible = false;

	constructor(
		private repository: RepositoryService,
		private snackBarCtrl: MatSnackBar,
		private modalCtrl: ModalController,
		private translate: TranslateService,
	) {
	}

	private _address: Address = null;

	get address(): Address {
		return this._address;
	}

	set address(value) {
		this._address = value;
		this.loadVenues();
	}

	static show(modalCtrl: ModalController, preorderType: PreorderType): Promise<SelectVenueModalResult> {
		return new Promise<SelectVenueModalResult>(async resolve => {
			const allowGpsResult = await AllowGpsModalComponent.show(modalCtrl);
			const modal = await modalCtrl.create({
				component: SelectVenueModalComponent,
				cssClass: 'select-venue-modal',
				componentProps: {
					allowGps: allowGpsResult === AllowGpsModalResult.ALLOW,
					preorderType,
				},
			});
			await modal.present();
			const result: SelectVenueModalResult = (await modal.onDidDismiss()).data;
			resolve(result);
		});
	}

	ngOnInit() {
		this.startUp();
	}

	async startUp() {
		if (this.allowGps) {
			try {
				this.address = await MapsUtils.getUserGeocode();
				await this.fillInPlace(this.address);
				await this.loadVenues();
			} catch (e) {
				// position could not be retrieved
			}
		}
		await this.initAutocomplete();
		this.loading = false;
	}

	async initAutocomplete() {
		await MapsUtils.initAutocomplete(await this.searchBar, (predictions) => {
			this.predictions = predictions;
		});
	}

	async loadVenues() {
		this.loading = true;
		this.venues = [];
		try {
			if (this.address) {
				this.venues = (await this.repository.getVenuesByAddress(this.address))
					.filter(ven => venueAcceptsOrders(ven, this.preorderType, false));
			}
		} catch (e) {
			this.snackBarCtrl.open('Error while loading venues please try it later again');
			await this.modalCtrl.dismiss();
		}
		if (this.venues.length <= 0) {
			this.loading = false;
			this.selectedVenue = null;
			this.snackBarCtrl.open(this.translate.instant('select_venue_modal.no_venues'), null, {
				duration: 2000,
			});
			return;
		}
		this.selectedVenue = this.venues[0];
		this.loading = false;
	}

	selectVenue(venue: Venue) {
		this.modalCtrl.dismiss(new SelectVenueModalResult(venue, this.address, PreorderType.DELIVERY));
	}

	async executeSearch() {
		this.loading = true;
		try {
			const result = await MapsUtils.executeSearch(this.searchBar);
			await this.fillInPlace(result);
		} catch (e) {
		}
		this.loading = false;
	}

	async resetSearch() {
		(await this.searchBar.getInputElement()).value = '';
		this.searchTerm = '';
		this.predictions = [];
		this.selectedVenue = null;
		if (this.address !== null) {
			this.repository.address.emit(null);
		}
	}

	async fillInPlace(address: Address) {
		this.loading = true;
		try {
			await MapsUtils.fillInPlace(this.searchBar, address, term => {
				if (term) {
					this.searchTerm = term;
				}
				return this.searchTerm;
			});
			if (!address) {
				await this.executeSearch();
				return;
			}
			this.loading = false;
			this.repository.address.emit(address);
		} catch (e) {
			this.snackBarCtrl.open(this.translate.instant(e), null, {
				duration: 2000,
			});
		}
		this.loading = false;
	}

	async setPredictionInvisible() {
		await sleep(500);
		this.predictionsVisible = false;
	}

	async selectPrediction(pred: AutocompletePrediction) {
		this.loading = true;
		try {
			this.address = MapsUtils.placeToAddress(await MapsUtils.getPlace(pred));
		} catch (e) {
			this.address = null;
		}
		await this.fillInPlace(this.address);
		this.venues = await this.repository.getVenuesByAddress(this.address);
		this.loading = false;
	}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}

}
