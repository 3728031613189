import { Component, Input, OnInit } from '@angular/core';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { AlertController, ModalController } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import RepositoryInterface from '../../../smoothr-web-app-core/directives/repository-directive';
import { OrderListAction } from '../../enums/OrderListAction';
import { AppComponent } from '../../app.component';
import { ModalInfoComponent } from '../modal-info/modal-info.component';
import { CheckoutModalComponent } from '../checkout-modal/checkout-modal.component';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { MenuPage } from '../../pages/menu/menu.page';
import Order from '../../../smoothr-web-app-core/models/Order';
import { PromoCodeType } from '../../../smoothr-web-app-core/models/PromoCodeType';
import { numberToCurrency, sendOrder } from '../../../smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { NoteArticleModalComponent } from '../note-article-modal/note-article-modal.component';

@Component({
	selector: 'app-order-content',
	templateUrl: './order-content.component.html',
	styleUrls: ['order-content.component.scss'],
})
export class OrderContentComponent extends RepositoryInterface implements OnInit {
	moment = moment;

	isValid = false;
	loading = false;

	ou = OrderUtils;
	pt = PreorderType;
	differenceToMvo: number;
	@Input()
	fromMenu = false;
	promoError: string;
	tip = 0;

	numberToCurrency = numberToCurrency;

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private analytics: AnalyticsService
	) {
		super(repository);
		this.validate();
	}

	get hasArticles(): boolean {
		return !!this.order && this.order.orderedArticles.length > 0;
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.openOrderContent();
	}

	onVenue() {
		super.onVenue();
		this.validate();
	}

	onOrder() {
		super.onOrder();
		if (this.order && this.order.orderedArticles && this.order.orderedArticles.length > 0) {
			this.analytics.orderHasProducts();
		}
		this.validate();
	}

	async onAction(index: number, event: OrderListAction) {
		switch (event) {
			case OrderListAction.add:
				this.order.orderedArticles[index].quantity += 1;
				break;
			case OrderListAction.delete:
				if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.order.promoCode.type === PromoCodeType.BOGO) {
					const bogoArticleIndex = this.order.orderedArticles.findIndex(ag => ag.isPromo);
					const bogoArticle = this.order.orderedArticles[bogoArticleIndex];
					const matchingBogoArticleIndices = this.order.orderedArticles
						.map((ag, agIndex) => {
							return ag.article._id === bogoArticle.article._id &&
								!ag.isPromo &&
								OrderUtils.bogoPrice(ag, this.order.type, this.order.preorder.type) ===
									OrderUtils.bogoPrice(bogoArticle, this.order.type, this.order.preorder.type)
								? agIndex
								: -1;
						})
						.filter(agIndex => agIndex !== -1);
					if (matchingBogoArticleIndices.find(bogoIndex => bogoIndex === index) !== undefined) {
						// article that should be removed is a possible parent of promoCode article
						if (matchingBogoArticleIndices.length === 1) {
							// only that article is possible parent of promoCode. If this article is removed the PromoCode and PromoArticle
							// should also be removed. Ask user.
							const bogoAlert = await this.alertCtrl.create({
								header: this.translate.instant('remove_bogo_alert.header'),
								message: this.translate.instant('remove_bogo_alert.message'),
								buttons: [
									{
										text: this.translate.instant('remove_bogo_alert.button_remove'),
										handler: () => {
											this.order.orderedArticles[bogoArticleIndex].isPromo = false;
											this.order.promoCode = null;
											this.order.orderedArticles.splice(index, 1);
											bogoAlert.dismiss();
										},
									},
									{
										text: this.translate.instant('remove_bogo_alert.button_keep'),
										handler: () => {
											bogoAlert.dismiss();
										},
									},
								],
							});
							await bogoAlert.present();
							await bogoAlert.onDidDismiss();
							break;
						}
					}
				}
				this.order.orderedArticles.splice(index, 1);
				break;
			case OrderListAction.note:
				this.noteArticle(index);
				break;
			case OrderListAction.edit:
				const modal = await this.modalCtrl.create({
					cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
					component: ModalInfoComponent,
					componentProps: {
						// copy article group
						articleGroup: JSON.parse(JSON.stringify(this.order.orderedArticles[index])),
						recommend: false,
					},
					showBackdrop: true,
					backdropDismiss: true,
				});
				await modal.present();
				const response = await modal.onDidDismiss();
				if (response.data && response.data.articleGroup) {
					const promoAgIndex = this.order.orderedArticles.findIndex(oa => oa.isPromo);
					if (
						OrderUtils.isBogoOrFreeArticlePromo(this.order) &&
						this.order.promoCode.type === PromoCodeType.BOGO &&
						this.order.orderedArticles[index].article._id === this.order.orderedArticles[promoAgIndex].article._id &&
						OrderUtils.bogoPrice(response.data.articleGroup, this.order.type, this.order.preorder.type) <
							OrderUtils.bogoPrice(this.order.orderedArticles[promoAgIndex], this.order.type, this.order.preorder.type)
					) {
						this.order.orderedArticles[promoAgIndex].isPromo = false;
						const newPromoAg = JSON.parse(JSON.stringify(response.data.articleGroup));
						newPromoAg.isPromo = true;
						this.order.orderedArticles.push(newPromoAg);
					}
					this.order.orderedArticles[index] = response.data.articleGroup;
					this.repository.order.emit(this.order);
				}
				break;
			case OrderListAction.remove:
				if (this.order.orderedArticles[index].quantity > 1) {
					this.order.orderedArticles[index].quantity -= 1;
				} else {
					await this.onAction(index, OrderListAction.delete);
					return;
				}
				break;
		}
		this.repository.order.emit(this.order);
	}

	async openCheckoutModal() {
		this.validate();
		if (!this.isValid || this.loading) {
			return;
		}
		this.loading = true;
		const result = await CheckoutModalComponent.show(this.modalCtrl, this.analytics, this.order, this.tip);
		if (result.data === undefined) {
			this.loading = false;
			return;
		}
		this.order.orderAt = result.data.orderAt;
		this.order.preorder = result.data.preorder;
		this.repository.order.emit(this.order);
		try {
			await sendOrder(
				this.router,
				this.modalCtrl,
				this.translate,
				this.repository,
				this.order,
				this.tip,
				loading => (this.loading = loading),
				this.snackbarCtrl
			);
		} catch (e) {
			console.log(JSON.parse(JSON.stringify(e)));
		}
	}

	validate() {
		if (!this.order || !this.venue || !this.order.preorder) {
			this.isValid = false;
			return;
		}
		const result = OrderUtils.validateOrder(this.venue, this.order);
		this.differenceToMvo = result.movDifference;
		this.isValid = result.valid;
	}

	totalPrice(): string {
		return numberToCurrency(OrderUtils.orderTotalPrice(this.order, true, true) + this.tip, this.order.currency);
	}

	replace(index: number, article: Article, options: ArticleOption[]) {
		this.order.orderedArticles[index].quantity = 1;
		this.order.orderedArticles[index].article = article;
		this.order.orderedArticles[index].groups = options;
	}

	onPromoApplied(order: Order) {
		this.promoError = null;
		this.repository.order.emit(order);
	}

	onDeletePromo(order: Order) {
		this.promoError = null;
		this.repository.order.emit(order);
	}

	onPromoCodeError(error: string) {
		if (!error) {
			// error just resetting error no error happened
			return;
		}
		// Promo error
		this.snackbarCtrl.open(error, null, { duration: 2000 });
		this.repository.order.emit(OrderUtils.removePromo(this.order));
		this.promoError = error;
	}

	backToMenu() {
		MenuPage.navigate(this.router);
	}
	async noteArticle(index: number) {
		const modal = await this.modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: NoteArticleModalComponent,
			componentProps: {
				// copy article group
				articleGroup: JSON.parse(
					JSON.stringify(this.order.orderedArticles[index])
				),
				recommend: false
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response?.data) {
			this.order.orderedArticles[index].note = response?.data;
		}
	}
}
