import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Information } from '../../../smoothr-web-app-core/models/Information';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';

@Component({
	selector: 'app-information-modal',
	templateUrl: './information-modal.component.html',
	styleUrls: ['information-modal.component.scss'],
})
export class InformationModalComponent implements OnInit {
	information: Information;

	constructor(private repository: RepositoryService, private modalCtrl: ModalController) {
	}

	static async show(modalCtrl: ModalController, information: Information) {
		const modal = await modalCtrl.create({
			component: InformationModalComponent,
			componentProps: {
				information,
			},
			cssClass: 'auto-height information-modal',
		});
		await modal.present();
	}

	ngOnInit() {}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
