import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { Router } from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';
import { ModalController } from '@ionic/angular';
import { SelectVenueModalComponent } from '../../components/select-venue-modal/select-venue-modal.component';
import { MenuPage } from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import { MapsUtils } from 'src/smoothr-web-app-core/utils/maps-utils';
import { MapPage } from '../map/map.page';
import { TranslateService } from '@ngx-translate/core';
enum Language {
	GreatBritain = 'en',
	German = 'de',
}
@Component({
	selector: 'app-home',
	templateUrl: './home.page.nordsee.html',
	styleUrls: ['./home.page.nordsee.scss'],
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY, PreorderType.DELIVERY];
	active: any = {};

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		private analytics: AngularFireAnalytics,
		private modalCtrl: ModalController,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		await router.navigateByUrl(HomePage.url);
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.loadVenues();
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores(preorderType: PreorderType) {
		// console.log(preorderType);
		// this.loading = true;
		// if (!this.active[preorderType]) {
		// 	this.loading = false;
		// 	return;
		// }

		// const venue = (await this.repository.getAllVenues()).find(ven => venueAcceptsOrders(ven, preorderType));
		// if (!venue) {
		// 	console.log('No available venues');
		// 	this.loading = false;
		// 	return;
		// }
		// await this.loadVenueAndCreateOrder(venue, null, preorderType);

		if (this.loading) { 
			return;
		}
		this.loading = true;
		this.cdr.detectChanges();
		try {
			const address = await MapsUtils.getUserGeocode();
			console.log(address)
			this.repository.address.emit(address);
		} catch (e) {
			console.error(e);
			this.repository.address.emit(null);
		}
		await MapPage.navigate(this.router);
		this.loading = false;
		this.cdr.detectChanges();

	}

	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		console.log('loadVenueAndCreateOrder(', venue, address, preorderType, ')');
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType);
		await MenuPage.navigate(this.router);
		this.loading = false;
	}

	loadVenues() {
		for (const preorderType of this.preorderTypes) {
			this.active[preorderType] = false;
		}
		new Promise<void>(async (resolve, reject) => {
			try {
				const venues = await this.repository.getAllVenues();
				for (const venue of venues) {
					for (const preorderType of this.preorderTypes) {
						console.log(venue);
						this.active[preorderType] = this.active[preorderType] || venueAcceptsOrders(venue, preorderType);
					}
				}
				resolve();
			} catch (e) {
				reject(e);
			}
		}).finally(() => (this.loading = false));
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
}
