import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { RepositoryService } from '../smoothr-web-app-core/services/repository/repository.service';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MenuPopoverComponent } from './components/menu-popover/menu-popover.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { CheckoutModalComponent } from './components/checkout-modal/checkout-modal.component';
import { HomePopoverComponent } from './components/home-popover/home-popover.component';
import { WorkingHoursModalComponent } from './components/working-hours-modal/working-hours-modal.component';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomePageModule } from './pages/home/home.module';
import { MenuPageModule } from './pages/menu/menu.module';
import { SuccessPageModule } from './pages/payment-success/payment-success.module';
import { VenuePageModule } from './pages/venue/venue.module';
import { OrderPageModule } from './pages/order/order.module';
import { SignUpPageModule } from './pages/sign-up/sign-up.module';
import { MyOrdersPageModule } from './pages/my-orders/my-orders.module';
import { SignInPageModule } from './pages/sign-in/sign-in.module';
import { EmailConfirmationPageModule } from './pages/email-confirmation/email-confirmation.module';
import { AccountPageModule } from './pages/account/account.module';
import { ModalNavigationGuard } from './modal-navigation-guard';
import { ShortInfoModalComponent } from './components/short-info-modal/short-info-modal.component';
import { AngularFireAnalyticsModule, COLLECTION_ENABLED } from '@angular/fire/analytics';
import { RecommendationModalComponent } from './components/recommendation-modal/recommendation-modal.component';
import { PaymentModalComponent } from '../smoothr-web-app-core/components/payment-modal/payment-modal.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { DeliveryNotAvailableModalComponent } from './components/delivery-not-available-modal/delivery-not-available-modal.component';
import { AnalyticsService } from '../smoothr-web-app-core/services/analytics/analytics.service';
import { CookieService } from 'ngx-cookie-service';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
	return () =>
		new Promise<any>((resolve: any) => {
			const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
			locationInitialized.then(() => {
				translate.use(translate.defaultLang).subscribe(
					() => {},
					() => {},
					() => resolve(null)
				);
			});
		});
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,
		ModalInfoComponent,
		CheckoutModalComponent,
		WorkingHoursModalComponent,
		ShortInfoModalComponent,
		DeliveryNotAvailableModalComponent,
		RecommendationModalComponent,
		PaymentModalComponent,
	],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			spinner: 'crescent',
			swipeBackEnabled: false,
			scrollAssist: false,
			mode: 'md',
		}),
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAuthModule,
		AngularFireAnalyticsModule,
		SharedModuleModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
		HttpClientModule,
		NoopAnimationsModule,
		FormsModule,
		HomePageModule,
		EmailConfirmationPageModule,
		MenuPageModule,
		SuccessPageModule,
		OrderPageModule,
		AccountPageModule,
		VenuePageModule,
		SignUpPageModule,
		MyOrdersPageModule,
		SignInPageModule,
		HttpClientModule,
		TranslateModule.forRoot({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
	],
	providers: [
		CookieService,
		AnalyticsService,
		RepositoryService,
		ModalNavigationGuard,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true,
		},
		{ provide: LOCALE_ID, useValue: 'de-DE' },
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: COLLECTION_ENABLED, useValue: false },
		{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
	],
	entryComponents: [
		RecommendationModalComponent,
		MenuPopoverComponent,
		HomePopoverComponent,
		ModalInfoComponent,
		DeliveryNotAvailableModalComponent,
		CheckoutModalComponent,
		WorkingHoursModalComponent,
		ShortInfoModalComponent,
		PaymentModalComponent,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
